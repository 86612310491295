import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { validateNumberInput } from "../Utils";
import "../Component_styles/index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../ContextApi";
import { NotokenFound } from "../Utils";
import { Delete_Icon } from "../Assets/index";
import { mainAPikey } from "../ContextApi";

const IpdForm = () => {
  const [pincodeData, setpincodeData] = useState([]);
  const [ipdFormData, setipdFormData] = useState([]);
  const [patientsData, setpatientsData] = useState([]);
  const [patientsMsg, setpatientsMsg] = useState("");
  const [EmployeeId, setEmployeeId] = useState(null);
  const [corporateId, setCorporateId] = useState(null);
  const [patientsId, setpatientsId] = useState(null);
  const [claimDocument, setClaimDocument] = useState([""]);
  const [UnAuthorized , setUnAuthorized] = useState(false)
  const [formData, setFormData] = useState(new FormData());
  const { activeElementId, policytypeidcontext , requestOptions ,setRequestOptions} = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("authorizationToken") || ""
  );
  const navigate = useNavigate();
  const [documentComponents, setDocumentComponents] = useState([
    {
      documentType: "",
      file: null,
    },
  ]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const ipdFormdata = watch();
  const hospitalizationDate = watch("hospitalizationDate");
  const dischargeDate = watch("dischargeDate");
  const validateDischargeDate = (dischargeDate) => {
    if (hospitalizationDate && dischargeDate) {
      const hospitalizationDateObj = new Date(hospitalizationDate);
      const dischargeDateObj = new Date(dischargeDate);

      if (dischargeDateObj < hospitalizationDateObj) {
        setError("dischargeDate", {
          type: "validate",
          message: "Discharge date cannot be before hospitalization date",
        });
      } else {
        clearErrors("dischargeDate");
      }
    }
  };


async  function handleTokenInResponse(response) {
    if(response.status === 401 || token === "" ){
      // NotokenFound(navigate)
      setUnAuthorized(true)
    }
    else{
      if (response.headers.has("Authorization")) {
        const authorizationToken = response.headers.get("Authorization");
        console.log("Found");
        localStorage.setItem("authorizationToken", authorizationToken);
        setRequestOptions((prevOptions) => ({
          ...prevOptions,
          headers: {
            ...prevOptions.headers,
            Authorization: authorizationToken,
          },
        }));
        setToken(authorizationToken)
        setUnAuthorized(false)
        const updatedResponse = new Response(response.body, {
          ...response,
          headers: {
            ...response.headers,
            Authorization: authorizationToken,
          },
        });
    
        return updatedResponse;
      } else {
        return response
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (token && mainAPikey) {
        try {
          const response = await fetch( process.env.REACT_APP_API_URL + `/${mainAPikey}` +
            `/employee/intimate-claims-patients-list?policy_id=${activeElementId}&policy_type=${policytypeidcontext}`,
            requestOptions
          );
  
          const updatedResponse = await handleTokenInResponse(response);
          const res = await updatedResponse.json();
          if (res.status === 429) {
            toast.error("Oops! You have reached maximum limit, please try again after sometime.");
          }
          else if (res.success) {
            setEmployeeId(res.data?.employee_details?.emp_id);
            setCorporateId(res.data?.employee_details?.corporate_id);
            setpatientsMsg(res.message);
            setpatientsData(res?.data?.patients);
            setpatientsId(res?.data?.id);
            setValue("policy_id", activeElementId);
            setValue("employee_details_id", res.data?.employee_details?.emp_id);
            setValue("corporate_id", res.data?.employee_details?.corporate_id);
          } else {
            setpatientsMsg(res.message);
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
        }
      }
    };
  
    fetchData();
  }, [activeElementId, token]);
  

  useEffect(() =>{
    if(!token || UnAuthorized ){
      NotokenFound(navigate)
    }
  },[])


  const pincode = watch("pincode");
  const onSubmit = (data) => {
    setIsLoading(true);
    const dataObject = {
      policy_id: ipdFormdata.policy_id,
      member_id: ipdFormdata.member_id,
      reason_for_claim: ipdFormdata.reason_for_claim,
      report_amount: ipdFormdata.report_amount,
      hospitalization_date: ipdFormdata.hospitalization_date,
      discharge_date: ipdFormdata.discharge_date,
      hospital_name: ipdFormdata.hospital_name,
      hospital_address: ipdFormdata.hospital_address,
      pincode: ipdFormdata.pincode,
      state: ipdFormdata.state,
      city: ipdFormdata.city,
      claim_type: ipdFormdata.claim_type,
      'document_type[]': ipdFormdata.document_type,
      corporate_id: ipdFormdata.corporate_id,
      employee_details_id: ipdFormdata.employee_details_id,
      source : 0
    };

    const requestOptionsCopy = { ...requestOptions };
    requestOptionsCopy.method = "POST";
    requestOptionsCopy.body = JSON.stringify(dataObject);

    requestOptionsCopy.processData = false;
    requestOptionsCopy.contentType = false;

   async function save_ipd_claim(){
      try {
        const response = await fetch( process.env.REACT_APP_API_URL + `/${mainAPikey}` +`/employee/save-ipd-claim-intimation`, requestOptionsCopy);
        const updatedResponse = await handleTokenInResponse(response);
        const res = await updatedResponse.json();
      
        if (res.status) {
          toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          setIsLoading(false);
          reset();
        } else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error verifying OTP:", error);
      } 
    }
    save_ipd_claim()
  };

  async function fetchData() {
    const response = await fetch(
      "https://api.postalpincode.in/pincode/" + pincode
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    if (data[0].Status === "Success") {
      const pincodeData = data[0].PostOffice[0];
      setValue("city", pincodeData.District);
      setValue("state", pincodeData.State);
      setpincodeData(pincodeData);
    } else {
      setpincodeData([]);
      setValue("city", "");
      setValue("state", "");
    }
  }

  useEffect(() => {
    if (pincode && pincode.length === 6) {
      fetchData();
      setValue("city", pincodeData.District);
      setValue("state", pincodeData.State);
    } else {
      setpincodeData([]);
    }
  }, [pincode]);

  const claim_document = watch()?.claim_document;
  const addDocumentComponent = () => {
    const updatedComponents = [
      ...documentComponents,
      {
        documentType: "",
        file: null,
      },
    ];
    setDocumentComponents(updatedComponents);
  };

  const deleteDocumentComponent = (index) => {
    const document_type = watch()?.document_type;

    setValue(
      "document_type",
      document_type.filter((_, ind) => ind !== index)
    );

    const updatedDocuments = documentComponents.filter(
      (_, ind) => ind !== index
    );
    setDocumentComponents(updatedDocuments);

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles.splice(index, 1); // Remove the file at the specified index
      return updatedFiles;
    });

    setSelectedFileNames((prevSelectedFileNames) => {
      const updatedFileNames = [...prevSelectedFileNames];
      updatedFileNames.splice(index, 1);
      return updatedFileNames;
    });
  };

  const handleFileChange = (index, e) => {
    const files = e.target.files;

    setSelectedFiles((prevSelectedFiles) => {
      const updatedFiles = [...prevSelectedFiles];
      updatedFiles[index] = files[0];
      return updatedFiles;
    });

    setSelectedFileNames((prevSelectedFileNames) => {
      const updatedFileNames = [...prevSelectedFileNames];
      updatedFileNames[index] = files[0] ? files[0].name : "";
      return updatedFileNames;
    });
  };

  useEffect(() => {
    setValue("claim_document", selectedFiles);
  }, [selectedFiles]);

  useEffect(() => {
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: token,
      },
    }));
  }, [token]);

  return (
    <>
      <div className="ipdform-cover-wrapper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Patient Name
                    <span className="required-input">*</span>
                  </label>
                  <select
                    {...register("member_id", {
                      required: "Patient Name is required",
                    })}
                  >
                    <option value="">Select a patient</option>
                    {patientsData &&
                      patientsData.map((option) => {
                        return <option value={option.id}>{option.name}</option>;
                      })}
                  </select>

                  {errors.member_id && (
                    <small className="text-danger">
                      Patient Name is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Claim Type <span className="required-input">*</span>
                  </label>

                  <div className="claim-type-radio-btns">
                    <div className="col-lg-12 col-12">
                    <div className="row">
                      <div className="col-lg-5 col-6">
                        <div className="claim-type-radio-box">
                          <label for="cashless" className="claim-type-label">
                            <input
                              type="radio"
                              name="claim_type"
                              id="cashless"
                              value="cashless"
                              {...register("claim_type", {
                                required: "Please select claim type",
                              })}
                            />
                            Cashless
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-7 col-6">
                        <div className="claim-type-radio-box">
                          <label for="reimbursement" className="claim-type-label">
                            <input
                              type="radio"
                              name="claim_type"
                              id="reimbursement"
                              value="reimbursement"
                              {...register("claim_type", {
                                required: "Please select claim type",
                              })}
                            />
                            Reimbursement
                          </label>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>

                  {errors.claimtype && (
                    <small className="text-danger">
                      Claim type is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Reason For Claim <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    name="reason_for_claim"
                    className="form-control"
                    id="reason_for_claim"
                    placeholder="Enter Reason for claim"
                    {...register("reason_for_claim", {
                      required: "Reason for claim is required",
                    })}
                  />
                  <input
                    type="hidden"
                    {...register("policy_id")}
                    value={activeElementId}
                  />
                  <input
                    type="hidden"
                    {...register("employee_details_id")}
                    value={EmployeeId}
                  />
                  <input
                    type="hidden"
                    {...register("corporate_id")}
                    value={corporateId}
                  />

                  {errors.reason_for_claim && (
                    <small className="text-danger">
                      Reason for claim is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Estimated / Claim Amount (₹)
                    <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    onInput={(e) => validateNumberInput(e)}
                    placeholder="Enter Estimated / Claim Amount (₹)'"
                    {...register("report_amount", {
                      required: "amount is required",
                      pattern: {
                        value: /^(0|[1-9]\d*)$/,
                        message: "invalid input amount",
                      },
                    })}
                  />
                  {errors.report_amount && (
                    <small className="text-danger">
                      {errors.report_amount.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Hospitalization Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    {...register("hospitalization_date", {
                      required: "Hospitalization Date is required",
                    })}
                  />
                  {errors.hospitalization_date && (
                    <small className="text-danger">
                      {errors.hospitalization_date.message}
                    </small>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Discharge Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id=""
                    {...register("discharge_date", {
                      required: "Discharge Date is required",
                    })}
                    onBlur={(e) => validateDischargeDate(e.target.value)}
                  />
                  {errors.discharge_date && (
                    <small className="text-danger">
                      {errors.discharge_date.message}
                    </small>
                  )}
                </div>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Name of Hospital <span className="required-input">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Name of Hospital"
                    {...register("hospital_name", {
                      required: "Hospital name is required",
                    })}
                  />
                  {errors.hospital_name && (
                    <small className="text-danger">
                      {errors.hospital_name.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Hospital Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Name of Hospital"
                    {...register("hospital_address", {
                      required: "Hospital Address is required",
                    })}
                  />
                  {errors.hospitaladdress && (
                    <small className="text-danger">
                      {errors.hospitaladdress.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Pincode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    maxLength={6}
                    onInput={(e) => validateNumberInput(e)}
                    placeholder="Enter Pincode"
                    {...register("pincode")}
                  />
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    {...register("city")}
                  />
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-input-box mb-3">
                <div className="form-input">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Enter State"
                    {...register("state")}
                  />
                  {errors.password && (
                    <small className="text-danger">
                      {errors.password.message || ""}
                    </small>
                  )}
                </div>
              </div>
            </div>
            
            {/* {documentComponents.map((component, index) => (
              <div key={uuidv4()}>
                <div className="my-document-wrapper">
                  <div
                    className="row mb-3 d-flex align-items-center"
                    id={`document_[${index}]`}
                  >
                    <div className="col-lg-4">
                      <div className="form-input-box mb-3">
                        <div className="form-input">
                          <label
                            htmlFor={`document_type[${index}]`}
                            className="form-label"
                          >
                            Document Type{" "}
                            <span className="required-input">*</span>
                          </label>
                          <Controller
                            name={`document_type[${index}]`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <select {...field}>
                                <option value="">Select Document type</option>
                                <option value="prescription">
                                  Prescription
                                </option>
                                <option value="medicinebills">
                                  Medicine Bills
                                </option>
                                <option value="labreport">Lab Report</option>
                                <option value="paidreceipt">
                                  Paid Receipt
                                </option>
                                <option value="cancelcheque">
                                  Cancel Cheque
                                </option>
                                <option value="other">Other</option>
                              </select>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-input-box mb-3 upload-document-box">
                        <div className="form-input">
                          <label
                            htmlFor={`claim_document[${index}]`}
                            className="form-label upload-label"
                          >
                            Upload Document  <small className="file_type">(Only PDF And Images Allowed)</small>
                          </label>
                          <input
                            name={`claim_document[${index}]`}
                            type="file"
                            accept="application/pdf,image/*"
                            id={`file[${index}]`}
                            onChange={(e) => handleFileChange(index, e)}
                          />
                          <div className="selected_filename">{selectedFileNames[index]}</div>
                        </div>
                      </div>
                    </div>
                    {index === 0 && (
                      <div className="col-lg-2">
                        <button
                          type="button"
                          className="AddnewDocbutton"
                          onClick={addDocumentComponent}
                        >
                          <span className="button__text">Add New</span>
                          <span className="button__icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              strokeLinejoin="round"
                              strokeLinecap="round"
                              stroke="currentColor"
                              height={24}
                              fill="none"
                              className="svg"
                            >
                              <line y2={19} y1={5} x2={12} x1={12} />
                              <line y2={12} y1={12} x2={19} x1={5} />
                            </svg>
                          </span>
                        </button>
                      </div>
                    )}
                    {index !== 0 && (
                      <div className="col-lg-1">
                        <div className="delete-btn-box">
                          <button
                            type="button"
                            onClick={() => deleteDocumentComponent(index)}
                          >
                            <img src={Delete_Icon}></img>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))} */}
          </div>
          <div className="submit-btn-box">
            <input
              type="submit"
              className="register-form-btn mt-4 mb-3"
              value={isLoading ? "please wait..." : "Submit"}
              disabled={isLoading}
            ></input>
          </div>
        </form>
        <ToastContainer
    position="top-center"
/>
      </div>
    </>
  );
};

// function Documentinput_Row(props) {
//   const {
//     register,
//     errors,
//     AddDocumentrow,
//     DeleteRow,
//     uniqueId,
//     components,
//     isFirst,
//     index,
//     handleFileUpload,
//     defaultValue,
//   } = props;
//   return (
//     <div className="my-document-wrapper">
//       <div className="row mb-3 d-flex align-items-center">
//         <div className="col-lg-4">
//           <div className="form-input-box mb-3">
//             <div className="form-input">
//               <label
//                 htmlFor={`documenttype_${component.id}`}
//                 className="form-label"
//               >
//                 Document Type <span className="required-input">*</span>
//               </label>
//               <Controller
//                 name={`documenttype_${component.id}`}
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <select {...field}>
//                     <option value="">Select Document type</option>
//                     <option value="prescription">Prescription</option>
//                     <option value="medicinebills">Medicine Bills</option>
//                     <option value="labreport">Lab Report</option>
//                     <option value="paidreceipt">Paid Receipt</option>
//                     <option value="cancelcheque">Cancel Cheque</option>
//                     <option value="other">Other</option>
//                   </select>
//                 )}
//               />
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-5">
//           <div className="form-input-box mb-3 upload-document-box">
//             <div className="form-input">
//               <label htmlFor={`file_${component.id}`} className="form-label">
//                 Upload Document
//               </label>
//               <input
//                 type="file"
//                 id={`file_${component.id}`}
//                 name={`filename_${component.id}`}
//                 {...register(`documentupload_${component.id}`, {
//                   required: "Please upload a document",
//                 })}
//               ></input>
//             </div>
//           </div>
//         </div>
//         {index !== 0 && ( // Display "Delete" button in all components except the first one
//           <div className="col-lg-1">
//             <div className="delete-btn-box">
//               <button
//                 type="button"
//                 onClick={() => deleteDocumentComponent(component.id)}
//               >
//                 Delete
//               </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

export default IpdForm;

import {
  productBike,
  productCar,
  productHealthFloater,
  productHealthIndividual,
} from "../Assets";
import { useContext } from "react";
import { AppContext } from "../ContextApi";
import { Link } from "react-router-dom";
import { ICARE_WEB_LINK } from "../Constants";

const InsuranceProducts = () => {
  const { employeeUserData } = useContext(AppContext);

  function base64Encode(data) {
    return btoa(JSON.stringify(data)); // Encode JSON string in base64
  }

  const handleUrl = (e, journey_type, request_type) => {
    e.preventDefault();

    const encryptedData = base64Encode({
      name: `${employeeUserData?.first_name} ${employeeUserData?.last_name}`,
      email: employeeUserData?.email,
      mobile: employeeUserData?.mobile_no,
      redirect_url : window.location.href,
      journey_type,
      request_type
    });
  
    const url = `${ICARE_WEB_LINK}/auto-login?data=${encodeURIComponent(encryptedData)}`;
    window.open(url, '_blank');
  };
  
  return (
    <>
      <div className="insurance-product-main-cover-wrapper">
        <div className="container">
          <div className="insurance-product-title-cover">
            <h2 className="text-center">Insurance Products</h2>
            <h5 className="text-center">
              ShieldGuard: Your One-Stop Insurance Destination
            </h5>
          </div>
          <div className="insurance-product-cover-box">
            <div className="insurance-content-cover">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-3">
                  <div className="insurance-product-box">
                    <h5>
                      Car <span className="">Insurance</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 1 , 1)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productCar} alt="car" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="insurance-product-box">
                    <h5>
                      Bike <span className="">Insurance</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 1 , 2)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productBike} alt="bike Insurance" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="insurance-product-box">
                    <h5>
                      Health <span className="">Individual</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e,2, 1)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productHealthFloater} alt="Health Insurance" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="insurance-product-box">
                    <h5>
                      Health <span className="">Floater</span>
                    </h5>
                    <Link className="get-quotes-btn" onClick={(e) =>handleUrl(e , 2 , 2)}>Get Quotes</Link>
                    <div className="product-icon-box">
                      <img src={productHealthIndividual} alt="Health Insurance" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceProducts;

import { toast } from "react-toastify";

export function validateNumberInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
}
export function numberToWords(number) {
    // Define arrays for one-digit and two-digit numbers
    const oneDigit = [
      "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
    ];
  
    const twoDigit = [
      "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen",
      "Sixteen", "Seventeen", "Eighteen", "Nineteen"
    ];
  
    const tens = [
      "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
    ];
  
    // Add appropriate suffix for thousand, million, billion, etc.
    const suffixes = ["", "Thousand", "Million", "Billion", "Trillion"];
  
    // Function to convert a chunk of up to three digits into words
    function convertChunk(num) {
      let words = "";
      if (num >= 100) {
        words += oneDigit[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num >= 10 && num <= 19) {
        words += twoDigit[num - 10];
      } else if (num >= 20) {
        words += tens[Math.floor(num / 10)];
        if (num % 10 !== 0) {
          words += " " + oneDigit[num % 10];
        }
      } else if (num > 0) {
        words += oneDigit[num];
      }
      return words.trim();
    }
  
    // Split the number into chunks of three digits
    let words = "";
    for (let i = 0; i < suffixes.length && number > 0; i++) {
      const chunk = number % 1000;
      if (chunk !== 0) {
        words = convertChunk(chunk) + " " + suffixes[i] + " " + words;
      }
      number = Math.floor(number / 1000);
    }
  
    return words.trim();
  }

export function NotokenFound(navigate){
  // console.log(token)
    // toast.error("An Error Occured", {
    //   position: toast.POSITION.TOP_CENTER,
    //   autoClose: 2000,
    // });
    setTimeout(() => {
      navigate("/");
    }, 500);
    return;
}

export  function handleTokenInResponse(response , setRequestOptions) {

  if (response.headers.has("Authorization")) {
    const authorizationToken = response.headers.get("Authorization");
    console.log("Yes Token Found from Header", authorizationToken);
    localStorage.setItem("authorizationToken", authorizationToken);
    setRequestOptions((prevOptions) => ({
      ...prevOptions,
      headers: {
        ...prevOptions.headers,
        Authorization: authorizationToken,
      },
    }));
    const updatedResponse = new Response(response.body, {
      ...response,
      headers: {
        ...response.headers,
        Authorization: authorizationToken,
      },
    });

    return updatedResponse;
  } else {
    console.log('Token Not Found');
    return response;
  }
}

export const formatNumber = (num) => {
  return num.toString().padStart(2, '0');
};

export function formatDateToYYYYMMDD(inputDate) {
  const year = inputDate.getFullYear();
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
  const day = inputDate.getDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

export function formatDateTimeFormat(dateString) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  
  return formattedDate;
}

export function convertTo12HourFormat(time24) {
  const [hours, minutes, seconds] = time24.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;

  return time12;
}

export async function TokenRefresh(refreshtoken) {
  if (refreshtoken === 'null') {
    console.log('No Token From Header Found. Need to wait For Expire');
  } else {
    try {
      localStorage.setItem('authorizationToken', refreshtoken.toString());
      const currentToken = localStorage.getItem('authorizationToken');
      console.log('New Token Found -->', currentToken);
    } catch (error) {
      console.error('Error while refreshing token:', error);
    }
  }
}
